import { getCookie, setCookie } from '../shared/utilities/cookies';
import { getUrlParams } from '../shared/utilities/getUrlParams';

const segParam = 'seg';
const segWpParam = 'segwp';
const segCookieName = 'iit_seg';
const aamSegCookieName = 'iit_seg_aam';
const segWpCookieName = 'iit_seg_wp';
const aamSegWpCookieName = 'iit_seg_aam_wp';
const cookieRegex = /^[a-zA-Z0-9-]+$/;

export function getSegmentationFromParams(queryParams, segKey) {
	if (queryParams && queryParams[segKey]) {
		return cookieRegex.test(queryParams[segKey].toString()) ? queryParams[segKey] : null;
	}

	return null;
}

/** This function will be deprecated in subsequent PR to support new cookie format */
export function getSegmentationFromCookie(aamCookie) {
	const aamSegPairs = aamCookie ? decodeURIComponent(aamCookie).split('-') : null;
	if (aamSegPairs) {
		const segs = [];
		for (let i = 0; i < aamSegPairs.length; ++i) {
			const seg = aamSegPairs[i].split('=');
			if (seg[1] && seg[1].match(cookieRegex)) {
				segs.push(seg[1]);
			}
		}
		if (segs.length > 0) {
			segs.sort((a, b) => a - b);
			return segs.pop();
		}
	}
	return null;
}

/** This function will be used going forward to decode cookie format(pipe format) */
export function getSegmentationFromAamWpCookie(aamCookie, delimiter = '|') {
	const aamSegs = aamCookie ? decodeURIComponent(aamCookie).split('=') : [];

	if (aamSegs[1]) {
		const segs = aamSegs[1]
			.split(delimiter)
			.filter(seg => seg && seg.match(cookieRegex))
			.sort((a, b) => a - b);
		if (segs.length) return segs.pop();
	}

	return null;
}

export function init(cookieName, cookieVal) {
	if (cookieVal) {
		const cookieExpiry = new Date('2099-01-01T00:00:00.000Z');
		setCookie(cookieName, cookieVal, cookieExpiry.getTime());
	}
}

function updateCookies() {
	/** set seg cookies */
	const segVal =
		getSegmentationFromParams(getUrlParams(), segParam) ||
		getSegmentationFromCookie(getCookie(aamSegCookieName));
	init(segCookieName, segVal);
	/** set seg amm wp cookies */
	const segWpVal =
		getSegmentationFromParams(getUrlParams(), segWpParam) ||
		getSegmentationFromAamWpCookie(getCookie(aamSegWpCookieName));
	init(segWpCookieName, segWpVal);
}
/*
 * Run segmentation cookie update on initial load.
 * */
updateCookies();
/*
 * Run subsequent cookie update post initial load by, allowing other scripts
 * to trigger update (response from inital request will be reused to update cookies).
 * */
window.syncSegCookies = updateCookies;
