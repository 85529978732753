export function getUrlParams(url = window.location.search) {
	if (!url) {
		return {};
	}

	return (/^[?#]/.test(url) ? url.slice(1) : url).split('&').reduce((params, param) => {
		const [key, value] = param.split('=');
		if (key !== '') {
			params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
		}
		return params;
	}, {});
}

export function getLowerCaseUrlParams(url = window.location.search) {
	const casedParams = getUrlParams(url);

	return Object.keys(casedParams).reduce(
		(acc, val) => ({
			...acc,
			[val.toLowerCase()]: casedParams[val]
		}),
		{}
	);
}
